/*****************
* Map Stylesheet *
*****************/

.map-container {
    position: relative;
    height: calc(100vh - 80px);
    width: 70%;
}

.search {
    position: absolute;
    z-index: 2;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: center;
}

.search-input {
    font-family: 'Poppins', sans-serif;
    padding: .8rem;
    border: solid 2px #0282C8;
}

.search__submit {
    padding: .8rem .5rem;
}

@media screen and (max-width: 900px) {
    .search {
        flex-direction: column;
    }
}