* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.bg{
    width: 18rem;
}

.logos{
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    margin-left: 100px;
}

.main-container {
    display: flex;
    padding: 30px;
}

.empty-list {
    height: calc(100vh - 80px);
    padding: 1rem;
    text-align: center;
    display: flex;
    align-items: center;
}

/* CARDS */

.cards {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.card {
    margin: 20px;
    padding: 20px;
    /* width: 500px; */
    display: flex;
    flex-direction: column;
    text-align: start;
    grid-template-rows: 20px 50px 1fr 50px;
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
    background-color: #F3F3F3;
}

.card-img-top {
    max-width: 100%;
}

.card-title {
    grid-row: 3/4;
    font-weight: 400;
    color: #111111;
}

.card__apply {
    grid-row: 4/5;
    align-self: center;
}

/* CARD BACKGROUNDS */

/* .card-1 {
    background-color: #F3F3F3;
}

.card-2 {
    background-color: #F3F3F3;
}

.card-3 {
    background-color: #F3F3F3;
}

.card-4 {
    background-color: #F3F3F3;
} */

/* RESPONSIVE */

@media (max-width: 1600px) {
    .cards {
        justify-content: center;
    }
}

.map {
    width: 100%;
    height: 1600px;
    position: sticky;
}
