/*  bas du site  */
.container {
    padding: 0 2rem;
}

.footer {
    display: flex;
    flex: 1;
    flex-direction: column;
    border: 1px solid #707070;
    padding-bottom: 40px;
}

.footer a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.row {
    display: flex;
    flex: 1;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 33%;
    padding: 10px;
}

.column img {
    height: 70px;
    max-width: 100%;
    object-fit: contain;
}

.smaller{
    height: 40px !important;
}
