/*****************
* App stylesheet *
*****************/

.app {
    position: relative;
}

.nav {
    width: 100vw;
    height: 80px;
}

.main {
    width: 100vw;
    height: calc(100vh - 80px);
    display: flex;
}

.list {
    height: calc(100vh - 80px);
    width: 30%;
    overflow: hidden;
    overflow-y: scroll;
}

@media (max-width: 520px) {
    .navi, header {
        flex-direction: column;
    }
}

@media (max-width: 700px) {
    .grid {
        width: 100%;
        flex-direction: column;
    }

    .row {
        flex-direction: column;
    }
}

